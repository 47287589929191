import React, { ReactNode } from 'react';
import TextHeader from './TextHeader';
import Paragraph from './Paragraph';

type HeaderProps = {
  title: ReactNode;
  subTitle: ReactNode;
};

const Header: React.FC<HeaderProps> = ({ title, subTitle }) => {
  return (
    <div className="sm:text-center">
      <TextHeader>{title}</TextHeader>
      <Paragraph text={subTitle} />
    </div>
  );
};

export default Header;
