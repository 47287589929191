import { FormValue } from './formValue.model';

const initialValue: FormValue = {
  name: '',
  workEmail: '',
  phone: '',
  countryCode: '66',
  corpEmail: '',
  companyName: '',
  website: '',
  phoneCorpOne: '',
  phoneCorpTwo: '',
  useSoftware: [],
  testSoftware: '',
  usePlan: '',
  problemRemark: '',
  isVerifyOTP: false,
  refOTP: '',
  timeout: 0,
};

export default initialValue;
