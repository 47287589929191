import React, { ReactNode } from 'react';
import classNames from 'classnames';

type ButtonReviewProps = {
  children: ReactNode;
  className?: string;
  active?: boolean;
  onClick?: () => void;
};

const ButtonReview: React.FC<ButtonReviewProps> = ({ children, onClick, active }) => {
  const buttonReviewClassName = classNames(
    'whitespace-nowrap inline-block rounded-md py-3 px-4 text-sm focus:outline-none border-solid border hover:bg-white hover:text-black',
    {
      'border-gray-400 text-gray-300': !active,
      'bg-white font-bold border-white text-black': active,
    },
  );

  return (
    <button onClick={() => onClick && onClick()} className={buttonReviewClassName}>
      {children}
    </button>
  );
};

export default ButtonReview;
