import React, { useState, useEffect } from 'react';
import { PinInput, PinInputField, HStack, useToast, Spinner } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import OTP_MUTATION, { OTPData, OTPVars } from 'apollo/graphql/mutation/otp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import GENERATE_OTP_PHONE_MUTATION, {
  GenerateOTPPhoneData,
  GenerateOTPPhoneVars,
} from 'apollo/graphql/mutation/generateOTPPhone';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { FormValue } from '../formValue.model';

dayjs.extend(duration);

type FormConfirmOTPProps = {
  onBack: () => void;
  onSubmit: () => void;
};

const FormConfirmOTP: React.FC<FormConfirmOTPProps> = ({ onBack, onSubmit }) => {
  const [isError, setError] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<string>('00:00');
  const [pinOTP, setPinOTP] = useState<string>('');
  const toast = useToast();
  const { watch, setValue } = useFormContext<FormValue>();
  const watchPhone = watch('phone');
  const watchCountryCode = watch('countryCode');
  const watchRefOTP = watch('refOTP');
  const watchTimeout = watch('timeout');

  const convertTimeoutToFormatTime = (timeout: number) => {
    const now = dayjs();
    const diffTimeout = dayjs(timeout).diff(now, 'milliseconds');
    return diffTimeout > 0 ? dayjs.duration(diffTimeout).format('mm:ss') : '00:00';
  };

  useEffect(() => {
    const timeout = setInterval(() => {
      setCountdown(convertTimeoutToFormatTime(watchTimeout));
    }, 1000);

    return () => {
      clearInterval(timeout);
    };
  }, [watchTimeout]);

  const [otp, { loading }] = useMutation<OTPData, OTPVars>(OTP_MUTATION, {
    update: (cache, { data }) => {
      if (data?.otp) {
        const { result } = data.otp;
        if (result) {
          setValue('isVerifyOTP', true);
          onSubmit();
        } else {
          setError(true);
        }
      }
    },
    onError: (err) => {
      toast({
        title: 'ERROR',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
  });

  const [generateOtpPhone, { loading: generateOtpPhoneLoading }] = useMutation<
    GenerateOTPPhoneData,
    GenerateOTPPhoneVars
  >(GENERATE_OTP_PHONE_MUTATION, {
    update: (cache, { data }) => {
      if (data?.generateOTPPhone) {
        const { ref, time_out } = data.generateOTPPhone;
        setValue('refOTP', ref);
        setValue('timeout', +time_out);
        setPinOTP('');
      }
    },
    onError: (err) => {
      toast({
        title: 'ERROR',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
  });

  const onSendAgain = () => {
    setError(false);
    generateOtpPhone({
      variables: {
        phone: `${watchCountryCode}${watchPhone.replace(/^[0]{1}([\d]{9})?$/g, '$1')}`,
      },
    });
  };

  return (
    <div>
      <div className="font-bold text-2xl mb-2">
        ยืนยันเบอร์โทรศัพท์<span className="inline-block">มือถือของคุณ</span>
      </div>
      <p className="text-sm mb-4">เราส่งรหัส (OTP) ไปที่หมายเลขโทรศัพท์ {watchPhone} ของคุณแล้ว</p>
      <div className="flex flex-row h-2 space-x-4 mb-14">
        <div className="bg-success rounded-full flex-1" />
        <div className="bg-gray-200 rounded-full flex-1" />
      </div>
      <div className="mb-14">
        <HStack className="mb-6" spacing="24px">
          <PinInput
            value={pinOTP}
            size="lg"
            placeholder=""
            variant="flushed"
            focusBorderColor="black"
            otp
            onComplete={(value) =>
              otp({
                variables: {
                  otp: value,
                  ref: watchRefOTP,
                },
              })
            }
            onChange={(value) => {
              setPinOTP(value);
              setError(false);
            }}
            autoFocus
          >
            <PinInputField className="font-bold" />
            <PinInputField className="font-bold" />
            <PinInputField className="font-bold" />
            <PinInputField className="font-bold" />
            <PinInputField className="font-bold" />
            <PinInputField className="font-bold" />
          </PinInput>
          <span className="hidden md:block">{loading && <Spinner />}</span>
        </HStack>
        <div className="text-sm text-danger font-bold mb-3 h-5">
          <span className={isError ? 'block' : 'hidden'}>
            <FontAwesomeIcon icon={faTimesCircle} /> ผิดพลาด! ดูเหมือนว่าคุณจะกรอกรหัส OTP ผิด
          </span>
        </div>
        <div className="text-sm text-gray-500">
          OTP Ref. {watchRefOTP} (Time {countdown})
        </div>
      </div>
      <div className="flex flex-row space-x-4">
        <button
          onClick={() => onBack()}
          className="w-full bg-transparent border-solid border border-gray-300 py-3 rounded-lg focus:outline-none"
        >
          กลับ
        </button>
        <button
          onClick={() => onSendAgain()}
          className={`w-full bg-transparent border-solid border border-gray-300 py-3 rounded-lg focus:outline-none ${
            generateOtpPhoneLoading || countdown !== '00:00' ? 'opacity-60' : ''
          }`}
          disabled={generateOtpPhoneLoading || countdown !== '00:00'}
        >
          ส่ง OTP อีกครั้ง
        </button>
      </div>
    </div>
  );
};

export default FormConfirmOTP;
