import React from 'react';
import {
  Stack,
  Textarea,
  Checkbox,
  CheckboxGroup,
  useToast,
  InputGroup,
  Input,
  InputLeftAddon,
} from '@chakra-ui/react';
import { useFormContext, Controller } from 'react-hook-form';
import CREATE_GTX_ONE_CONTACT_MUTATION, {
  CreateGtxOneContactData,
  CreateGtxOneContactVars,
} from 'apollo/graphql/mutation/createGtxOneContact';
import { useMutation } from '@apollo/client';
import { FormValue } from '../formValue.model';

type FormContactProps = {
  onSubmit: () => void;
};

const FormContact: React.FC<FormContactProps> = ({ onSubmit }) => {
  const { control, handleSubmit, errors, watch } = useFormContext<FormValue>();
  const watchCountryCode = watch('countryCode');
  const toast = useToast();

  const [createGtxOneContact, { loading }] = useMutation<
    CreateGtxOneContactData,
    CreateGtxOneContactVars
  >(CREATE_GTX_ONE_CONTACT_MUTATION, {
    update: (cache, { data }) => {
      if (data?.createGtxOneContact) {
        onSubmit();
      }
    },
    onError: (err) => {
      toast({
        title: 'ERROR',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
  });

  const onConfirmSend = handleSubmit((values) => {
    createGtxOneContact({
      variables: {
        name: values.name,
        work_email: values.workEmail,
        phone: values.phone,
        use_software: values.useSoftware.map((item) => +item),
        contact_remark: values.contactRemark,
      },
    });
  });

  return (
    <div>
      <div className="font-bold text-2xl mb-2">ติดต่อเรา</div>
      <p className="text-sm mb-9">หลังจากส่งข้อความแล้วเราจะเร่งติดต่อกลับหาคุณภายใน 24 ชั่วโมง</p>
      <div className="mb-9">
        <div className="font-bold text-lg mb-4">ข้อมูลติดต่อของคุณ</div>
        <div className="space-y-4">
          <Controller
            name="name"
            control={control}
            render={({ value, onChange }) => (
              <Input
                isInvalid={!!errors.name}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                focusBorderColor="gray.300"
                placeholder="ชื่อผู้ติดต่อ"
                size="md"
              />
            )}
          />
          <Controller
            name="workEmail"
            control={control}
            render={({ value, onChange }) => (
              <Input
                isInvalid={!!errors.workEmail}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                focusBorderColor="gray.300"
                placeholder="อีเมลทำงาน (Work E-mail)"
                size="md"
              />
            )}
          />

          <Controller
            name="phone"
            control={control}
            render={({ value, onChange }) => (
              <InputGroup>
                <InputLeftAddon children={`+${watchCountryCode} (Thai)`} />

                <Input
                  isInvalid={!!errors.phone}
                  value={value}
                  onChange={(event) => {
                    onChange(event.target.value);
                  }}
                  focusBorderColor="gray.300"
                  type="tel"
                  placeholder="เบอร์โทรศัพท์มือถือ (ไม่บังคับ)"
                  size="md"
                />
              </InputGroup>
            )}
          />
        </div>
      </div>
      <div className="mb-10">
        <div className="font-bold text-lg mb-4">
          ผลิตภัณฑ์ที่สนใจ<span className="text-danger">*</span>
        </div>
        <div className="space-y-4">
          <Controller
            control={control}
            name="useSoftware"
            render={({ value, onChange }) => (
              <CheckboxGroup
                colorScheme="green"
                value={value}
                onChange={(valueChange) => {
                  onChange(valueChange);
                }}
              >
                <Stack spacing="4">
                  <Checkbox value="1">On-demand service platform</Checkbox>
                  <Checkbox value="2">Transport management system</Checkbox>
                  <Checkbox value="3">Warehouse management system</Checkbox>
                  <Checkbox value="4">Finance system</Checkbox>
                  <Checkbox value="5">Overall Monitor</Checkbox>
                </Stack>
              </CheckboxGroup>
            )}
          />
        </div>
      </div>

      <div className="mb-10">
        <div>
          <Controller
            control={control}
            name="contactRemark"
            render={({ value, onChange }) => (
              <Textarea
                focusBorderColor="gray.300"
                placeholder="ข้อความ"
                value={value}
                onChange={(valueChange) => {
                  onChange(valueChange);
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="flex flex-row space-x-4">
        <button
          onClick={() => onConfirmSend()}
          className={`w-full bg-success py-3 rounded-lg text-white focus:outline-none ${
            loading ? 'opacity-60' : ''
          }`}
          disabled={loading}
        >
          ส่งข้อความ
        </button>
      </div>
    </div>
  );
};

export default FormContact;
