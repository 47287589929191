import * as yup from 'yup';

const validateSchema = yup.object().shape({
  name: yup.string().required('กรุณากรอกข้อมูล'),
  countryCode: yup.string().required('กรุณากรอกข้อมูล'),
  workEmail: yup
    .string()
    .matches(
      /^(([a-zA-Z0-9]+([\_\.][a-zA-Z0-9]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'รูปแบบไม่ถูกต้อง',
    )
    .required('กรุณากรอกข้อมูล'),
  phone: yup.string().matches(/(^$|^[0]\d{9}$)/, 'รูปแบบไม่ถูกต้อง'),
  useSoftware: yup.array().min(1, 'เลือกอย่างน้อย 1 รายการ'),
  contactRemark: yup.string(),
});

export default validateSchema;
