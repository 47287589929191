import gql from 'graphql-tag';

export interface CreateGtxOneRegisterData {
  createGtxOneRegister: {
    _id: string;
  };
}

export interface CreateGtxOneRegisterVars {
  name: string;
  work_email: string;
  company_name: string;
  website: string;
  corp_email: string;
  phone: string[];
  use_software: number[];
  test_software: number;
  use_plant: number;
  problems_remark: string;
}

export default gql`
  mutation CreateGtxOneRegister(
    $name: String
    $work_email: String
    $company_name: String
    $website: String
    $corp_email: String
    $phone: [String]
    $use_software: [Int]
    $test_software: Int
    $use_plant: Int
    $problems_remark: String
  ) {
    createGtxOneRegister(
      create: {
        name: $name
        work_email: $work_email
        company_name: $company_name
        website: $website
        corp_email: $corp_email
        phone: $phone
        use_software: $use_software
        test_software: $test_software
        use_plant: $use_plant
        problems_remark: $problems_remark
      }
    ) {
      _id
    }
  }
`;
