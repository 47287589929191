import gql from 'graphql-tag';

export interface OTPData {
  otp: {
    result: boolean;
  };
}

export interface OTPVars {
  ref: string;
  otp: string;
}

export default gql`
  mutation OTP($ref: String!, $otp: String!) {
    otp(ref: $ref, otp: $otp) {
      result
    }
  }
`;
