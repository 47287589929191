import React, { ReactNode } from 'react';

type ParagraphProps = {
  text: ReactNode;
};

const Paragraph: React.FC<ParagraphProps> = ({ text }) => {
  return <div className="text-gray-300 text-sm sm:text-lg">{text}</div>;
};

export default Paragraph;
