import React from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ButtonSwitchLanguage: React.FC = () => {
  const { originalPath, language } = useI18next();
  const { t } = useTranslation();

  return (
    <Link to={originalPath} language={language === 'th' ? 'en' : 'th'}>
      <button
        className="w-36 text-center text-white bg-transparent border border-solid border-gray-400 hover:bg-white hover:text-black active:bg-white text-md px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1"
        type="button"
        style={{ transition: 'all .15s ease' }}
      >
        <FontAwesomeIcon icon={faGlobe} className="mr-2" />
        {t('lang')}
      </button>
    </Link>
  );
};

export default ButtonSwitchLanguage;
