import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import Logo from 'assets/svg/logo.svg';
import Arrow from 'assets/svg/arrow.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons';

type ModalAboutProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalAbout: React.FC<ModalAboutProps> = (props) => {
  const { visible, onCancel } = props;

  return (
    <Drawer isOpen={visible} placement="right" onClose={() => onCancel()} size="lg">
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton className="text-black" />
          <DrawerBody className="text-black my-10">
            <div className="px-2 py-2 md:px-10 md:py-10 mb-20">
              <div className="mb-9 md:mb-16 inline-block h-14 w-56">
                <Logo />
              </div>
              <div className="font-bold text-2xl md:text-3xl mb-5">เราคือใคร ?</div>
              <p>
                GIZTIX บริษัท Tech Startup ที่มุ่งมั่นในการพัฒนาซอฟต์แวร์ด้านโลจิสติกส์
                เพื่อการเติบโตของภาคธุรกิจ และยกระดับคุณภาพชีวิตของผู้คนในสังคม
              </p>
              <div className="border-solid border-gray-300 border-b my-10 w-full" />
              <div className="font-bold text-2xl md:text-3xl mb-5">ทำอะไรมาบ้าง ?</div>
              <p className="mb-9">
                GIZTIX ได้พัฒนาบริการจองรถขนส่งสินค้าออนไลน์{' '}
                <span className="inline-block">
                  <a
                    href="https://giztix.com"
                    className="text-blue-500 font-bold"
                    target="_blank"
                    rel="noreferrer"
                  >
                    On demand truck service <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </a>
                </span>{' '}
                สามารถเช็คราคา ติดตามสินค้า ซึ่งเป็นที่ยอมรับจากลูกค้าองค์กรธุรกิจเป็นอย่างมาก
              </p>
              <p>
                นอกจากนี้ GIZTIX ยังได้พัฒนาระบบในการบริหารจัดการทางด้านโลจิสติกส์
                เพื่อลดความผิดพลาดเพิ่มความสะดวกสบาย และความรวดเร็ว
                ซึ่งการันตีได้จากจำนวนลูกค้าที่ใช้บริการกว่า 300 บริษัท
              </p>
              <div className="border-solid border-gray-300 border-b my-10 w-full" />
              <div className="font-bold text-2xl md:text-3xl mb-5">
                GIZTIX ONE Solution <span className="inline-block">คืออะไร ?</span>
              </div>
              <p className="mb-9">
                จากประสบการณ์ที่ผ่านมา จึงทำให้ GIZTIX มีแนวคิดที่จะรวมทุกผลิตภัณฑ์
                และบริการที่พัฒนาอย่างสร้างสรรค์ เข้าไว้ด้วยกันเพื่อให้เกิดประสิทธิภาพสูงสุด
                และยังเป็นการสร้างเครือข่ายของผู้ใช้งาน สามารถเชื่อมธุรกิจเข้าด้วยกัน ให้ GIZTIX
                เป็นศูนย์กลางในการพัฒนาระบบบริหารจัดการ สำหรับธุรกิจขนส่งแบบครบวงจร ภายใต้ชื่อ{' '}
                <span className="font-bold">“GIZTIX ONE Solution”</span>
              </p>
              <p className="mb-9">
                GIZTIX ONE Solution ศูนย์รวมของซอฟต์แวร์ด้านโลจิสติกส์
                เพื่อการบริหารธุรกิจให้เติบโตอย่างมีประสิทธิภาพและยั่งยืน
                ด้วยระบบในการบริหารงานที่ครบวงจร ตั้งแต่ จัดหา จัดการ ตรวจสอบ
                รวมไปถึงการวิเคราะห์เพื่อสรุปผล ให้กับธุรกิจเพื่อการพัฒนาและสร้างผลกำไร
              </p>
              <p className="mb-9 md:mb-20 font-bold">
                “หนึ่งบัญชีผู้ใช้ หลากหลายซอฟต์แวร์ หลากหลายแอปพลิเคชั่น”
              </p>
              <div className="w-28 h-28 opacity-20 mx-auto">
                <Arrow />
              </div>
            </div>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default ModalAbout;
