import React, { useState } from 'react';
import Helmet from 'react-helmet';
import _ from 'lodash';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import { Navbar } from 'components';
import { config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import Header from '../templates/Header';
import Badge from '../templates/Badge';
import Button from '../templates/Button';
import TextHeader from '../templates/TextHeader';
import CardReview from '../templates/CardReview';
import ButtonReview from '../templates/ButtonReview';
import Footer from '../templates/Footer';
import { StyledSimpleBar } from './styles';
import BgObject from 'assets/svg/bg-object.svg';
import 'simplebar/src/simplebar.css';
import Loadable from '@loadable/component';
import JUMC2017 from 'assets/svg/jumc-2017.svg';
import TechSauce2016 from 'assets/svg/techsauce-2016.svg';
import Echelon2015 from 'assets/svg/echelon-2015.svg';
import Ict2015Retail from 'assets/svg/ict-2015-retail.svg';
import Ict2015StartUpCompany from 'assets/svg/ict-2015-start-up-company.svg';
import Graph from 'assets/svg/graph.svg';
import GraphBar1 from 'assets/svg/graph-bar-1.svg';
import GraphBar2 from 'assets/svg/graph-bar-2.svg';

const LoadableModalRegister = Loadable(() => import('components/ModalRegister'));
const LoadableModalContact = Loadable(() => import('components/ModalContact'));
const LoadableModalAbout = Loadable(() => import('components/ModalAbout'));
const ODS = Loadable(() => import('assets/svg/products/ods.svg'));
const TMS = Loadable(() => import('assets/svg/products/tms.svg'));
const WMS = Loadable(() => import('assets/svg/products/wms.svg'));
const FIN = Loadable(() => import('assets/svg/products/fin.svg'));
const OMG = Loadable(() => import('assets/svg/products/omg.svg'));

config.autoAddCss = false;

type HomePageProps = {
  data: {
    review: {
      reviews: {
        comment: string;
        company: string;
        customerName: string;
        position: string;
        product: string;
      }[];
    };
  };
};

const HomePage: React.FC<HomePageProps> = ({ data }) => {
  const { t } = useTranslation();
  const [selectedReview, setSelectedReview] = useState<string>('Transport management system');
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const [isOpenModalRegister, setOpenModalRegister] = useState<boolean>(false);
  const [isOpenModalContact, setOpenModalContact] = useState<boolean>(false);
  const [isOpenModalAbout, setOpenModalAbout] = useState<boolean>(false);

  const filterReview = data.review.reviews.filter((item) => item.product === selectedReview);
  const renderButtonReview = [
    {
      title: 'Transport management system',
    },
    {
      title: 'On-demand service platform',
    },
    {
      title: 'Financial management system',
    },
  ].map((item, index) => (
    <ButtonReview
      key={index}
      active={item.title === selectedReview}
      onClick={() => setSelectedReview(item.title)}
    >
      {item.title}
    </ButtonReview>
  ));

  return (
    <>
      <Helmet>
        <title>GIZTIX ONE Solution ซอฟต์แวร์สำหรับธุรกิจขนส่งและคลังสินค้า</title>
        <meta charSet="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta
          name="description"
          content="GIZTIX ONE Solution ซอฟต์แวร์สำหรับธุรกิจขนส่งและคลังสินค้า ยกระดับการทำงาน สร้างสมดุลให้ธุรกิจขนส่ง"
        />
        <meta
          name="keywords"
          content="GIZTIX, GIZTIX ONE Solution, บริการขนส่งสินค้า, บริษัทขนส่ง, รถขนส่งสินค้า, รถรับจ้าง, ขนย้าย, ขนส่งสินค้า, รถกระตู้ทึบขนส่ง, รถกระบะตู้คอกขนส่ง, ขนส่งสินค้า, รถบรรทุก 6 ล้อตู้ทึบ, รถบรรทุก 6 ล้อตู้คอก, รถบรรทุก 10 ล้อตู้ทึบ, รถบรรทุก 10 ล้อตู้คอก, ธุรกิจขนส่ง, ขนส่ง 76 จังหวัด, บริการขนส่งออนไลน์"
        />
        <meta name="author" content="giztix" />
        <meta name="copyright" content="giztix" />
        <meta name="revisit-after" content="7 days" />
        <meta name="robots" content="index, follow" />
        <meta property="og:url" content="https://giztixone.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/logo-share.jpg" />
        <meta property="og:title" content="Giztix One Solution" />
        <meta
          property="og:description"
          content="GIZTIX ONE Solution ซอฟต์แวร์สำหรับธุรกิจขนส่งและคลังสินค้า ยกระดับการทำงาน สร้างสมดุลให้ธุรกิจขนส่ง"
        />
        <meta name="application-name" content="giztix" />
      </Helmet>

      <main>
        <div className="relative 2xl:container 2xl:mx-auto">
          <div className="w-full h-[600px] md:h-[1000px] absolute overflow-hidden">
            <div
              className="animate-spin ml-auto w-[600px] md:w-[1000px] h-full relative left-1/3 lg:-top-40 2xl:left-auto 2xl:right-0 opacity-70 p-5"
              style={{
                animationDuration: '200s',
              }}
            >
              <BgObject />
            </div>
          </div>
        </div>

        <Navbar />

        <div className="relative container mx-auto px-8">
          <section className="flex flex-row mb-28 md:mb-52">
            <div className="mr-20">
              <h1 className="text-2xl font-bold leading-relaxed mb-2 md:text-6xl md:leading-relaxed sm:text-5xl sm:leading-normal">
                ยกระดับการทำงาน <div className="whitespace-nowrap">สร้างสมดุลให้ธุรกิจขนส่ง</div>
              </h1>
              <div className="text-gray-300 mb-20 md:text-lg">
                GIZTIX ONE Solution{' '}
                <span className="inline-block">
                  ซอฟต์แวร์เพื่อการบริหารธุรกิจขนส่งและคลังสินค้า
                </span>
              </div>

              <div className="mb-5 sm:flex sm:flex-row sm:items-center">
                <button
                  onClick={() => setOpenModalRegister(true)}
                  className="text-white bg-success px-11 py-4 rounded-lg text-xl mb-5 sm:mb-0 sm:mr-10 focus:outline-none font-bold"
                >
                  ลงทะเบียนฟรี
                </button>
                <LoadableModalRegister
                  visible={isOpenModalRegister}
                  onCancel={() => setOpenModalRegister(false)}
                />
                <div className="text-gray-300 text-lg">รับส่วนลดฟรีทันที 50%</div>
              </div>
              <div>
                <button
                  className="text-gray-300 sm:hidden focus:outline-none"
                  onClick={() => setOpenModalAbout(true)}
                >
                  เกี่ยวกับเรา <FontAwesomeIcon icon={faAngleRight} className="text-sm" />
                  <LoadableModalAbout
                    visible={isOpenModalAbout}
                    onCancel={() => setOpenModalAbout(false)}
                  />
                </button>
              </div>
              <div className="hidden sm:flex sm:flex-col opacity-50 space-y-3 mt-32">
                <div className="h-14 lg:h-16 flex flex-row md:space-x-1 space-x-5">
                  <JUMC2017 className="h-full" />
                  <TechSauce2016 className="h-full" />
                </div>
                <div className="h-14 lg:h-16 flex flex-row md:space-x-1 space-x-5">
                  <Echelon2015 className="h-full" />
                  <Ict2015Retail className="h-full" />
                  <Ict2015StartUpCompany className="h-full" />
                </div>
              </div>
            </div>
            <div className="hidden 2xl:block w-3/6">{/* <Image name="notebook.png" /> */}</div>
          </section>

          <section
            className="mb-9 lg:mb-28"
            data-sal="slide-up"
            data-sal-duration="2000"
            data-sal-easing="ease"
          >
            <Header
              title={
                <div>
                  จะเป็นอย่างไร{' '}
                  <span className="inline-block">ถ้าเราสามารถรวมทุกอย่างไว้ด้วยกัน</span>
                </div>
              }
              subTitle={
                <div>
                  GIZTIX ONE Solution การรวมกันของซอฟต์แวร์ เครื่องมือสำหรับธุรกิจขนส่งและคลังสินค้า
                  <span className="sm:block">เพื่อเพิ่มประสิทธิภาพการทำงานให้ไร้ขีดจำกัด</span>
                </div>
              }
            />
          </section>

          <section className="relative mb-16 lg:mb-36">
            <div className="hidden lg:block absolute opacity-10 bottom-0">
              <span
                style={{ letterSpacing: '5rem' }}
                className="text-white font-bold leading-none lg:text-[300px] xl:text-[400px]"
              >
                ODS
              </span>
            </div>
            <div className="relative lg:flex lg:flex-row lg:space-x-10 justify-between">
              <div
                className="flex-1 flex flex-row justify-center items-center"
                data-sal="slide-up"
                data-sal-duration="2000"
                data-sal-easing="ease"
              >
                <ODS className="w-full md:w-3/4" />
              </div>
              <div
                className="lg:w-5/12"
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-easing="ease"
              >
                <Badge text="เร็วๆ นี้" color="primary" />
                <div className="text-xl sm:text-3xl font-bold mb-3">On-demand service platform</div>
                <div className="text-sm sm:text-xl mb-7">
                  แพลตฟอร์มช่องทางการขายบริการขนส่งออนไลน์{' '}
                  <span className="md:block">แบบครบวงจร สะดวก ง่ายต่อการบริหาร</span>
                </div>
                <div>
                  <ul className="list-disc text-sm sm:text-lg list-outside mb-9 ml-5 lg:w-3/4">
                    <li className="mb-5">ปรับภาพลักษณ์บริษัทขนส่งของคุณ บนตลาดออนไลน์</li>
                    <li className="mb-5">
                      เพียง 5 คลิก ลูกค้าของคุณก็สามารถซื้อบริการรถขนส่งคุณได้
                      โดยไม่ต้องยกหูโทรศัพท์ถามราคา
                    </li>
                    <li>สามารถสร้างราคาขายขนส่ง 76 จังหวัดได้ โดยไม่ต้องใส่สูตรให้ปวดหัว</li>
                  </ul>
                </div>
                <Button
                  className="mb-4"
                  onClick={() => {
                    setSelectedProduct('1');
                    setOpenModalContact(true);
                  }}
                >
                  ติดต่อขอทดลองใช้
                </Button>
                <div className="text-gray-400 text-sm">
                  หรือทดลองเช็คราคาเองได้ที่{' '}
                  <a href="https://app.giztix.com" target="_blank" rel="noreferrer">
                    app.giztix.com <FontAwesomeIcon icon={faExternalLinkAlt} className="text-sm" />
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="relative mb-16 lg:mb-36">
            <div className="hidden lg:block absolute opacity-10 right-0 bottom-0">
              <span
                style={{ letterSpacing: '5rem' }}
                className="text-white font-bold leading-none lg:text-[300px] xl:text-[400px]"
              >
                TMS
              </span>
            </div>
            <div className="relative lg:flex lg:flex-row lg:space-x-10 justify-between">
              <div
                className="flex-1 flex flex-row justify-center items-center lg:order-1"
                data-sal="slide-up"
                data-sal-duration="2000"
                data-sal-easing="ease"
              >
                <TMS className="w-full md:w-3/4" />
              </div>
              <div
                className="lg:w-5/12"
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-easing="ease"
              >
                <Badge text="เร็วๆ นี้" color="primary" />
                <div className="text-xl sm:text-3xl font-bold mb-3">
                  Transport management system
                </div>
                <div className="text-sm sm:text-xl mb-7">
                  ระบบบริหารรถขนส่งที่มีประสิทธิภาพและครอบคลุม{' '}
                  <span className="md:block">ไม่ต้องปวดหัววางแผนการเดินเที่ยวรถอีกต่อไป</span>
                </div>
                <div>
                  <ul className="list-disc text-sm sm:text-lg list-outside mb-9 ml-5 lg:w-3/4">
                    <li className="mb-5">จัดการต้นทุนค่าขนส่งแบบอัตโนมัติ</li>
                    <li className="mb-5">ฟังก์ชันวางแผนการจัดเที่ยวรถขนส่งแบบอัตโนมัติ</li>
                    <li className="mb-5">จัดการต้นทุนค่าขนส่งแบบอัตโนมัติ</li>
                    <li>
                      Driver App
                      เครื่องมือสำหรับพนักงานขับรถเพื่อให้คุณได้ติดตามการดำเนินงานได้ทุกขั้นตอน
                    </li>
                  </ul>
                </div>
                <Button
                  className="mb-4"
                  onClick={() => {
                    setSelectedProduct('2');
                    setOpenModalContact(true);
                  }}
                >
                  ติดต่อขอทดลองใช้
                </Button>
                <div className="text-gray-400 text-sm">
                  ให้ผู้เชียวชาญทางเทคนิคเป็นคนแนะนำผลิตภัณฑ์นี้{' '}
                  <span className="md:block">
                    รองรับธุรกิจขนาดเล็ก และรูปแบบเฉพาะสำหรับธุรกิจขนาดใหญ่
                  </span>
                </div>
              </div>
            </div>
          </section>

          <section className="relative mb-16 lg:mb-36">
            <div className="hidden lg:block absolute opacity-10 bottom-0">
              <span
                style={{ letterSpacing: '5rem' }}
                className="text-white font-bold leading-none lg:text-[300px] xl:text-[400px]"
              >
                WMS
              </span>
            </div>
            <div className="relative lg:flex lg:flex-row lg:space-x-10 justify-between">
              <div
                className="flex-1 flex flex-row justify-center items-center"
                data-sal="slide-up"
                data-sal-duration="2000"
                data-sal-easing="ease"
              >
                <WMS className="w-full md:w-3/4" />
              </div>
              <div
                className="lg:w-5/12"
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-easing="ease"
              >
                <Badge text="เร็วๆ นี้" color="primary" />
                <div className="text-xl sm:text-3xl font-bold mb-3">
                  Warehouse management system
                </div>
                <div className="text-sm sm:text-xl mb-7">
                  ระบบบริหารคลังสินค้าที่ง่ายและใช้ทรัพยากรน้อย
                </div>
                <div>
                  <ul className="list-disc text-sm sm:text-lg list-outside mb-9 ml-5 lg:w-3/4">
                    <li className="mb-5">แนะนำการใช้พื้นที่คลังสินค้าให้มีประสิทธิภาพสูงสุด</li>
                    <li className="mb-5">หน้าจอแสดงภาพรวมของจำนวนสินค้าตลอดเวลา</li>
                    <li>
                      Sorter App เครื่องมือที่ทำงานได้อย่างครอบคลุมทุกขั้นตอน
                      <span className="md:inline-block">
                        ทั้งตรวจรับ ค้นหา จัดเรียง ก็ทำได้อย่างแม่นยำ
                      </span>
                    </li>
                  </ul>
                </div>
                <Button
                  className="mb-4"
                  onClick={() => {
                    setSelectedProduct('3');
                    setOpenModalContact(true);
                  }}
                >
                  ติดต่อขอทดลองใช้
                </Button>
                <div className="text-gray-400 text-sm">
                  ให้ผู้เชียวชาญทางเทคนิคเป็นคนแนะนำผลิตภัณฑ์นี้
                </div>
              </div>
            </div>
          </section>

          <section className="lg:flex lg:flex-row space-y-5 lg:space-x-5 lg:space-y-0 mb-16 md:mb-40">
            <div
              className="flex-1 rounded-3xl text-center bg-card-background p-9 sm:p-16"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-easing="ease"
            >
              <div className="w-32 mb-9 mx-auto">
                <FIN />
              </div>
              <div className="text-xl md:text-2xl font-bold mb-4 md:mb-8">
                Financial <span className="inline-block">management system</span>
              </div>
              <div className="text-sm md:text-lg text-gray-300 mx-auto mb-6 max-w-sm">
                ระบบจัดการบัญชี ออกแบบให้รองรับธุรกิจขนส่ง และคลังสินค้าโดยเฉพาะ ที่เชื่อมต่อกับระบบ
                TMS และ WMS ของ GIZTIX ONE Solution
              </div>
              <Badge text="กำลังพัฒนา" />
            </div>
            <div
              className="flex-1 rounded-3xl text-center bg-card-background p-9 sm:p-16"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-easing="ease"
            >
              <div className="w-32 mb-9 mx-auto">
                <OMG />
              </div>
              <div className="text-xl md:text-2xl font-bold mb-4 md:mb-8">Over monitor</div>
              <div className="text-sm md:text-lg text-gray-300 mx-auto mb-6 max-w-sm">
                ซอฟต์แวร์ที่ช่วยวิเคราะห์และสรุปผล ภาพรวมการดำเนินงานสำหรับธุรกิจขนส่ง คลังสินค้า
                และการเงินขององค์กร
              </div>
              <Badge text="กำลังพัฒนา" />
            </div>
          </section>

          <section
            className="mb-9 lg:mb-28"
            data-sal="slide-up"
            data-sal-duration="2000"
            data-sal-easing="ease"
          >
            <Header
              title={
                <div>
                  การทำงานเร็วขึ้นกว่ามาก{' '}
                  <span className="inline-block">เมื่อเทียบกับเมื่อก่อน</span>
                </div>
              }
              subTitle="เปรียบเทียบการทำงานระหว่างไม่ใช้ซอฟต์แวร์ และใช้ซอฟต์แวร์จาก GIZTIX ONE Solution"
            />
          </section>

          <section
            className="mb-16 lg:mb-40"
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <div className="mb-6 md:mb-14 w-full max-w-4xl mx-auto">
              <div className="relative">
                <div className="w-full">
                  <Graph />
                </div>
                <div
                  className="absolute w-[20%] bottom-[13%] left-[23%]"
                  data-sal="slide-up"
                  data-sal-duration="2000"
                  data-sal-easing="ease"
                  data-sal-delay="500"
                >
                  <GraphBar1 />
                </div>
                <div
                  className="absolute w-[20%] bottom-[13%] left-[69%]"
                  data-sal="slide-up"
                  data-sal-duration="2000"
                  data-sal-easing="ease"
                  data-sal-delay="1000"
                >
                  <GraphBar2 />
                </div>
              </div>
            </div>
            <ul className="text-sm text-gray-400 max-w-3xl mx-auto space-y-1">
              <li>
                [2] ขั้นตอนการทำงานสำหรับงานขนส่งและงานคลังสินค้า เฉลี่ย 20 งาน ต่อ 12 ชั่วโมง
              </li>
              <li>
                [3] อ้างอิงจากการเก็บข้อมูลผู้ใช้งานผลิตภัณฑ์ของ บริษัท จิซทิกซ์ จำกัด ในกลุ่ม TMS
                เปรียบเทียบระหว่างใช้กับไม่ใช้ซอฟต์แวร์จาก{' '}
                <span className="inline-block">GIZTIX ONE Solution</span> ข้อมูลงานคลังสินค้า
                จากคลังสินค้า GIZTIX (GIZTIX SORTING CENTRE BKK){' '}
              </li>
            </ul>
          </section>

          <section className="lg:flex lg:flex-row lg:space-x-10 mb-16 lg:mb-56 z-10 relative">
            <div
              className="w-full lg:w-4/12"
              data-sal="slide-up"
              data-sal-duration="1500"
              data-sal-easing="ease"
            >
              <TextHeader>
                สิ่งที่หาไม่ได้จากที่ไหน <span className="block md:mt-3">และไม่มีใครแทนที่ได้</span>
              </TextHeader>
            </div>

            <div className="flex-1 space-y-4 md:space-x-4 md:flex md:flex-row md:space-y-0">
              <div className="space-y-4 flex-1">
                <div
                  className="rounded-3xl bg-card-background p-6 md:p-9"
                  data-sal="slide-up"
                  data-sal-duration="2000"
                  data-sal-easing="ease"
                >
                  <div className="text-sm md:text-xl mb-2 md:mb-8">
                    เครือข่ายของธุรกิจขนส่ง{' '}
                    <span className="inline-block">จะช่วยให้รถและงานสมดุล</span>
                  </div>
                  <div className="text-2xl md:text-4xl font-bold text-success">ให้สมดุล</div>
                </div>
                <div
                  className="rounded-3xl bg-card-background p-6 md:p-9"
                  data-sal="slide-up"
                  data-sal-duration="2000"
                  data-sal-easing="ease"
                >
                  <div className="text-2xl md:text-4xl font-bold text-yellow-500 mb-2 md:mb-8">
                    มีรถว่าง{' '}
                    <span className="inline-block md:inline lg:inline-block">
                      แต่งานก็ไม่มาซักที
                    </span>
                  </div>
                  <div className="text-sm md:text-xl mb-3">
                    GIZTIX ONE Solution สามารถเชื่อมต่อและรับงานขนส่งจากผู้ใช้ GIZTIX ONE Solution{' '}
                    <span className="inline-block">รายอื่นๆ</span>{' '}
                    ที่ต้องการรถขนส่งสินค้าได้ตลอดเวลา
                  </div>
                </div>
              </div>

              <div className="space-y-4 flex-1">
                <div
                  className="rounded-3xl bg-card-background p-6 md:p-9"
                  data-sal="slide-up"
                  data-sal-duration="2000"
                  data-sal-easing="ease"
                >
                  <div className="text-2xl md:text-4xl font-bold text-blue-500 mb-2 md:mb-8">
                    งานเข้า <span className="inline-block">แต่รถเราไม่ว่าง</span>
                  </div>
                  <div className="text-sm md:text-xl mb-3">
                    GIZTIX ONE Solution หรือ GIZTIX EXPRESS รับรองว่าได้รถแน่นอน
                  </div>
                </div>
                <div
                  className="rounded-3xl border-card-background border-2 border-solid p-6 md:p-9"
                  data-sal="slide-up"
                  data-sal-duration="2000"
                  data-sal-easing="ease"
                >
                  <div className="text-2xl font-bold">รับส่วนลดทันที 50%</div>
                  <div className="text-sm mb-4">เมื่อลงทะเบียนให้ความสนใจซอฟต์แวร์ล่วงหน้า</div>
                  <Button onClick={() => setOpenModalRegister(true)}>ลงทะเบียน</Button>
                </div>
              </div>
            </div>
          </section>

          <section className="mb-20 lg:mb-60 relative">
            <div className="relative z-10">
              <div data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                <TextHeader>
                  ประสบการณ์ตรงจากผู้ใช้ซอฟต์แวร์ <div>GIZTIX ONE Solution</div>
                </TextHeader>
              </div>

              <div
                className="mb-10 overflow-x-auto"
                data-sal="slide-up"
                data-sal-duration="2000"
                data-sal-easing="ease"
              >
                <div className="flex flex-row space-x-5">{renderButtonReview}</div>
              </div>

              <div data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                <StyledSimpleBar autoHide={false}>
                  <div className="flex pb-5">
                    <div className="flex flex-row space-x-5">
                      {_.chunk(filterReview, 2).map((chunkItem, indexChunkItem) => (
                        <div
                          className="flex flex-row items-start md:flex-col space-x-5 md:space-y-5 md:space-x-0"
                          key={indexChunkItem}
                        >
                          {chunkItem.map((item, indexItem) => (
                            <div className="w-72" key={indexItem}>
                              <CardReview
                                comment={item.comment}
                                customerName={item.customerName}
                                company={item.company}
                              />
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </StyledSimpleBar>
              </div>
            </div>
          </section>
        </div>

        <div className="relative overflow-hidden pb-10 md:pb-20">
          <div className="hidden md:block absolute top-0 -right-80 opacity-30 w-full h-full">
            <BgObject />
          </div>

          <div className="relative container mx-auto px-8">
            <Footer />
          </div>
        </div>

        <LoadableModalContact
          defaultValue={[selectedProduct]}
          visible={isOpenModalContact}
          onCancel={() => setOpenModalContact(false)}
        />
      </main>
    </>
  );
};

export default HomePage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    review: dataJson {
      reviews {
        comment
        company
        customerName
        position
        product
      }
    }
  }
`;
