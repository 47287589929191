import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';

type CardReviewProps = {
  customerName: string;
  company: string;
  comment: string;
};

const CardReview: React.FC<CardReviewProps> = ({ customerName, comment, company }) => {
  return (
    <div className="flex flex-col p-5 rounded-lg bg-card-background text-sm">
      <div className="mb-5 text-gray-300">
        <FontAwesomeIcon icon={faQuoteLeft} />
      </div>
      <div>{`“${comment}”`}</div>
      <div className="border-solid border-gray-500 border-b my-6" />
      <div className="font-bold mb-1">{customerName}</div>
      <div className="text-gray-300">{company}</div>
    </div>
  );
};

export default CardReview;
