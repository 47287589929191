import React, { useState } from 'react';
import { ButtonSwitchLanguage, Button } from 'components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ModalAbout from '../ModalAbout';
import Logo from '../Logo';

const Navbar: React.FC = () => {
  const { t } = useTranslation();
  const [isOpenModalAbout, setOpenModalAbout] = useState<boolean>(false);

  return (
    <nav className="relative flex flex-wrap items-center justify-between navbar-expand-lg bg-transparent mb-3">
      <div className="container mx-auto px-8 py-6 sm:py-8 flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between sm:w-auto">
          <Logo />
        </div>

        <div className="hidden sm:flex flex-grow items-center">
          <ul className="flex flex-col sm:flex-row list-none ml-auto">
            <li className="nav-item">
              <Button className="mr-10" onClick={() => setOpenModalAbout(true)}>
                {t('button.about')}
              </Button>
              <ModalAbout visible={isOpenModalAbout} onCancel={() => setOpenModalAbout(false)} />
            </li>
            <li className="nav-item">
              <ButtonSwitchLanguage />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
