import { FormValue } from './formValue.model';

const initialValue: FormValue = {
  name: '',
  workEmail: '',
  phone: '',
  useSoftware: [],
  contactRemark: '',
  countryCode: '66',
};

export default initialValue;
