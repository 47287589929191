import React from 'react';
import Thankful from 'assets/svg/thankful.svg';

type FormSuccessProps = {
  onClose: () => void;
};

const FormSuccess: React.FC<FormSuccessProps> = ({ onClose }) => {
  return (
    <div className="text-center mt-10">
      <div className="w-28 h-28 mx-auto mb-10">
        <Thankful />
      </div>

      <div className="font-bold text-2xl mb-7">ขอบคุณสำหรับการติดต่อ</div>

      <div className="mb-20">
        <div>เราจะเร่งติดต่อกลับหาคุณภายใน 24 ชั่วโมง</div>
      </div>

      <button
        onClick={() => onClose()}
        className="max-w-xs w-full bg-transparent border-solid border border-gray-300 py-3 rounded-lg focus:outline-none"
      >
        ปิดหน้าต่างนี้
      </button>
    </div>
  );
};

export default FormSuccess;
