import React, { ReactNode } from 'react';

type ButtonProps = {
  onClick?: () => void;
  className?: string;
  children: ReactNode;
};

const Button: React.FC<ButtonProps> = (props) => {
  const { children, className, ...otherProps } = props;

  return (
    <button
      className={`text-center text-white bg-transparent hover:underline text-md px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ${
        className || ''
      }`}
      type="button"
      style={{ transition: 'all .15s ease' }}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default Button;
