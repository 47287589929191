import React from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import LogoSvg from 'assets/svg/logo-white.svg';

const Logo: React.FC = () => {
  const { language } = useI18next();

  return (
    <Link to="/" language={language} className="inline-block mr-4 py-2">
      <div style={{ width: 165, height: 36 }}>
        <LogoSvg />
      </div>
    </Link>
  );
};

export default Logo;
