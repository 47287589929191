import React from 'react';
import Thankful from 'assets/svg/thankful.svg';

type FormSuccessProps = {
  onClose: () => void;
};

const FormSuccess: React.FC<FormSuccessProps> = ({ onClose }) => {
  return (
    <div className="text-center mt-10">
      <div className="w-28 h-28 mx-auto mb-10">
        <Thankful />
      </div>

      <div className="font-bold text-2xl mb-7">
        ขอบคุณที่ร่วมเป็นส่วนหนึ่ง <span className="block">ของการพัฒนาที่ยิ่งใหญ่</span>
      </div>

      <div className="mb-20">
        <div className="mb-4">มีข้อสงสัยสอบถามเพิ่มเติมได้ที่:</div>
        <div className="mb-2">
          <span className="font-bold text-lg">คุณโสฬส อดุลย์จิตต์ (บิน)</span>
          <span className="block">ผู้จัดการฝ่ายขาย GIZTIX ONE Solution</span>
        </div>

        <div className="text-sm text-gray-500">
          <div>เบอร์โทรติดต่อ: (+66) 9-2246-3652</div>
          <div>อีเมล: soros@giztix.com</div>
        </div>
      </div>

      <button
        onClick={() => onClose()}
        className="max-w-xs w-full bg-transparent border-solid border border-gray-300 py-3 rounded-lg focus:outline-none"
      >
        ปิดหน้าต่างนี้
      </button>
    </div>
  );
};

export default FormSuccess;
