import React, { useState, useEffect } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import validateSchema from './validateSchema';
import { FormValue } from './formValue.model';
import initialValue from './initialValue';
import FormContact from './FormContact';
import FormSuccess from './FormSuccess';

type ModalContactProps = {
  visible: boolean;
  onCancel: () => void;
  defaultValue?: string[];
};

const ModalContact: React.FC<ModalContactProps> = (props) => {
  const { visible, onCancel, defaultValue } = props;
  const [step, setStep] = useState<'contact' | 'success'>('contact');
  const methods = useForm<FormValue>({
    resolver: yupResolver(validateSchema),
    defaultValues: initialValue,
    shouldUnregister: false,
  });

  const { errors, reset } = methods;

  useEffect(() => {
    if (visible) {
      setStep('contact');
      reset({
        ...initialValue,
        useSoftware: defaultValue || [],
      });
    }

    return () => {
      reset({
        ...initialValue,
      });
    };
  }, [visible]);

  return (
    <Drawer isOpen={visible} placement="right" onClose={() => onCancel()} size="lg">
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton className="text-black" />
          <FormProvider {...methods}>
            <DrawerBody className="text-black my-5">
              <div className="px-2 py-2 md:px-10 md:py-10">
                {step === 'contact' && <FormContact onSubmit={() => setStep('success')} />}
                {step === 'success' && <FormSuccess onClose={() => onCancel()} />}
              </div>
            </DrawerBody>
          </FormProvider>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default ModalContact;
