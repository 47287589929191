import React, { ReactNode } from 'react';

type ButtonProps = {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
};

const Button: React.FC<ButtonProps> = ({ children, className, onClick }) => {
  return (
    <button
      className={`text-white bg-success text-sm px-5 py-3 rounded-md inline-block font-bold mr-1 mb-1 focus:outline-none ${
        className || ''
      }`}
      onClick={() => onClick && onClick()}
    >
      {children}
    </button>
  );
};

export default Button;
