import gql from 'graphql-tag';

export interface CreateGtxOneContactData {
  createGtxOneContact: {
    _id: string;
  };
}

export interface CreateGtxOneContactVars {
  name: string;
  work_email: string;
  phone: string;
  use_software: number[];
  contact_remark: string;
}

export default gql`
  mutation CreateGtxOneContact(
    $name: String
    $work_email: String
    $phone: String
    $use_software: [Int]
    $contact_remark: String
  ) {
    createGtxOneContact(
      create: {
        name: $name
        work_email: $work_email
        phone: $phone
        use_software: $use_software
        contact_remark: $contact_remark
      }
    ) {
      _id
    }
  }
`;
