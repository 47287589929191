import React from 'react';
import classNames from 'classnames';

type BadgeProps = {
  text: string;
  color?: 'primary';
};

const Badge: React.FC<BadgeProps> = ({ text, color }) => {
  const badeClassName = classNames(
    'text-sm rounded px-4 py-2 border-solid border inline-block mr-1 mb-1',
    {
      'border-gray-400 text-gray-400': color === undefined,
      'border-primary text-primary': color === 'primary',
    },
  );

  return <div className={badeClassName}>{text}</div>;
};

export default Badge;
