import React, { ReactNode } from 'react';

type TextHeaderProps = {
  children: ReactNode;
  className?: string;
};

const TextHeader: React.FC<TextHeaderProps> = ({ children, className }) => {
  return (
    <div className={`text-xl sm:text-2xl md:text-4xl font-bold mb-4 ${className || ''}`}>
      {children}
    </div>
  );
};

export default TextHeader;
