import gql from 'graphql-tag';

export interface GenerateOTPPhoneData {
  generateOTPPhone: {
    ref: string;
    phone: string;
    time_out: string;
  };
}

export interface GenerateOTPPhoneVars {
  phone: string;
}

export default gql`
  mutation GenerateOTPPhone($phone: String!) {
    generateOTPPhone(phone: $phone) {
      ref
      phone
      time_out
    }
  }
`;
