import React from 'react';
import { Input, InputLeftAddon, InputGroup, useToast } from '@chakra-ui/react';
import { useFormContext, Controller } from 'react-hook-form';
import GENERATE_OTP_PHONE_MUTATION, {
  GenerateOTPPhoneData,
  GenerateOTPPhoneVars,
} from 'apollo/graphql/mutation/generateOTPPhone';
import { useMutation } from '@apollo/client';
import { FormValue } from '../formValue.model';

type FormInformationProps = {
  onCancel: () => void;
  onSubmit: () => void;
};

const FormInformation: React.FC<FormInformationProps> = ({ onCancel, onSubmit }) => {
  const toast = useToast();
  const { control, trigger, errors, watch, setValue } = useFormContext<FormValue>();
  const [generateOtpPhone, { loading }] = useMutation<GenerateOTPPhoneData, GenerateOTPPhoneVars>(
    GENERATE_OTP_PHONE_MUTATION,
    {
      update: (cache, { data }) => {
        if (data?.generateOTPPhone) {
          const { ref, time_out } = data.generateOTPPhone;
          setValue('refOTP', ref);
          setValue('timeout', +time_out);
          onSubmit();
        }
      },
      onError: (err) => {
        toast({
          title: 'OTP',
          description: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      },
    },
  );
  const watchIsVerifyOTP = watch('isVerifyOTP');
  const watchPhone = watch('phone');
  const watchCountryCode = watch('countryCode');

  return (
    <div>
      <div className="font-bold text-2xl mb-2">ลงทะเบียนล่วงหน้าเพื่อรับสิทธิ์พิเศษฟรี</div>
      <p className="text-sm mb-4">
        รับส่วนลดค่าซอฟต์แวร์ไปเลยทันที 50% เมื่อทำการลงทะเบียนและตอบคำถาม
        <span className="lg:block">
          ขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขโดยมิต้องแจ้งให้ทราบล่วงหน้า
        </span>
      </p>
      <div className="flex flex-row h-1 space-x-4 mb-9">
        <div className="bg-success rounded-full flex-1" />
        <div className="bg-gray-200 rounded-full flex-1" />
      </div>
      <div className="mb-9">
        <div className="font-bold text-lg mb-4">ข้อมูลติดต่อของคุณ</div>
        <div className="space-y-4">
          <Controller
            name="name"
            control={control}
            render={({ value, onChange }) => (
              <Input
                isInvalid={!!errors.name}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                focusBorderColor="gray.300"
                placeholder="ชื่อผู้ติดต่อ"
                size="md"
              />
            )}
          />
          <Controller
            name="workEmail"
            control={control}
            render={({ value, onChange }) => (
              <Input
                isInvalid={!!errors.workEmail}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                focusBorderColor="gray.300"
                placeholder="อีเมลทำงาน (Work E-mail)"
                size="md"
              />
            )}
          />

          <Controller
            name="phone"
            control={control}
            render={({ value, onChange }) => (
              <InputGroup>
                <InputLeftAddon children={`+${watchCountryCode} (Thai)`} />
                <Input
                  isInvalid={!!errors.phone}
                  value={value}
                  onChange={(event) => {
                    if (watchIsVerifyOTP) {
                      setValue('isVerifyOTP', false);
                    }

                    onChange(event.target.value);
                  }}
                  focusBorderColor="gray.300"
                  type="tel"
                  placeholder="เบอร์โทรศัพท์มือถือ เพื่อรับ OTP"
                  size="md"
                />
              </InputGroup>
            )}
          />

          {/* <InputGroup>
            <Controller
              name="countryCode"
              control={control}
              render={({ value }) => <InputLeftAddon children={`+${value} (Thai)`} />}
            />

            <Controller
              name="phone"
              control={control}
              render={({ value, onChange }) => (
                <Input
                  isInvalid={!!errors.phone}
                  value={value}
                  onChange={(event) => {
                    if (watchIsVerifyOTP) {
                      setValue('isVerifyOTP', false);
                    }

                    onChange(event.target.value);
                  }}
                  focusBorderColor="gray.300"
                  type="tel"
                  placeholder="เบอร์โทรศัพท์มือถือ เพื่อรับ OTP"
                  size="md"
                />
              )}
            />
          </InputGroup> */}
          {watchIsVerifyOTP && (
            <div className="text-sm text-success font-bold h-5 text-right">
              <span>ยืนยัน OTP แล้ว</span>
            </div>
          )}
        </div>
      </div>
      <div className="mb-9">
        <div className="font-bold text-lg mb-4">ข้อมูลธุรกิจ/องค์กร</div>
        <div className="space-y-4">
          <Controller
            name="companyName"
            control={control}
            render={({ value, onChange }) => (
              <Input
                isInvalid={!!errors.companyName}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                focusBorderColor="gray.300"
                placeholder="ชื่อบริษัท"
                size="md"
              />
            )}
          />
          <Controller
            name="website"
            control={control}
            render={({ value, onChange }) => (
              <Input
                isInvalid={!!errors.website}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                focusBorderColor="gray.300"
                placeholder="เว็บไซต์ (ถ้ามี)"
                size="md"
              />
            )}
          />
          <Controller
            name="corpEmail"
            control={control}
            render={({ value, onChange }) => (
              <Input
                isInvalid={!!errors.corpEmail}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                focusBorderColor="gray.300"
                placeholder="อีเมล (Corporate E-mail)"
                size="md"
              />
            )}
          />
          <div className="flex flex-row space-x-4">
            <Controller
              name="phoneCorpOne"
              control={control}
              render={({ value, onChange }) => (
                <Input
                  isInvalid={!!errors.phoneCorpOne}
                  value={value}
                  onChange={(event) => onChange(event.target.value)}
                  focusBorderColor="gray.300"
                  placeholder="เบอร์โทรศัพท์"
                  size="md"
                />
              )}
            />

            <Controller
              name="phoneCorpTwo"
              control={control}
              render={({ value, onChange }) => (
                <Input
                  isInvalid={!!errors.phoneCorpTwo}
                  value={value}
                  onChange={(event) => onChange(event.target.value)}
                  focusBorderColor="gray.300"
                  placeholder="เบอร์โทรศัพท์ (ไม่บังคับ)"
                  size="md"
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row space-x-4">
        <button
          onClick={() => onCancel()}
          className="w-full bg-transparent border-solid border border-gray-300 py-3 rounded-lg focus:outline-none"
        >
          ยกเลิก
        </button>
        <button
          onClick={() => {
            trigger([
              'name',
              'workEmail',
              'phone',
              'companyName',
              'website',
              'phoneCorpOne',
              'phoneCorpTwo',
              'corpEmail',
            ]).then((valid) => {
              if (!valid) {
                return;
              }

              if (watchIsVerifyOTP) {
                onSubmit();
              } else {
                generateOtpPhone({
                  variables: {
                    phone: `${watchCountryCode}${watchPhone.replace(/^[0]{1}([\d]{9})?$/g, '$1')}`,
                  },
                });
              }
            });
          }}
          className={`w-full bg-success py-3 rounded-lg text-white focus:outline-none ${
            loading ? 'opacity-75' : ''
          }`}
          disabled={loading}
        >
          ต่อไป
        </button>
      </div>
    </div>
  );
};

export default FormInformation;
