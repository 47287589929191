import React from 'react';
import {
  RadioGroup,
  Stack,
  Radio,
  Textarea,
  Checkbox,
  CheckboxGroup,
  useToast,
} from '@chakra-ui/react';
import { useFormContext, Controller } from 'react-hook-form';
import CREATE_GTX_ONE_REGISTER_MUTATION, {
  CreateGtxOneRegisterData,
  CreateGtxOneRegisterVars,
} from 'apollo/graphql/mutation/createGtxOneRegister';
import { useMutation } from '@apollo/client';
import { FormValue } from '../formValue.model';

type FormBusinessProps = {
  onBack: () => void;
  onSubmit: () => void;
};

const FormBusiness: React.FC<FormBusinessProps> = ({ onBack, onSubmit }) => {
  const { control, handleSubmit, setValue } = useFormContext<FormValue>();
  const toast = useToast();

  const [createGtxOneRegister, { loading }] = useMutation<
    CreateGtxOneRegisterData,
    CreateGtxOneRegisterVars
  >(CREATE_GTX_ONE_REGISTER_MUTATION, {
    update: (cache, { data }) => {
      if (data?.createGtxOneRegister) {
        setValue('isVerifyOTP', false);
        onSubmit();
      }
    },
    onError: (err) => {
      toast({
        title: 'ERROR',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
  });

  const onConfirmSend = handleSubmit((values) => {
    createGtxOneRegister({
      variables: {
        name: values.name,
        work_email: values.workEmail,
        company_name: values.companyName,
        website: values.website,
        corp_email: values.corpEmail,
        phone: [
          values.phone,
          values.phoneCorpOne,
          ...(values.phoneCorpTwo ? [values.phoneCorpTwo] : []),
        ],
        use_software: values.useSoftware.map((item) => +item),
        test_software: +values.testSoftware,
        use_plant: +values.usePlan,
        problems_remark: values.problemRemark,
      },
    });
  });

  return (
    <div>
      <div className="font-bold text-2xl mb-2">แบบสอบถามธุรกิจ</div>
      <p className="text-sm mb-4">
        รับส่วนลดค่าซอฟต์แวร์ไปเลยทันที 50% เมื่อทำการลงทะเบียนและตอบคำถาม
        <span className="md:block">
          ขอสงวนสิทธิ์การเปลี่ยนแปลงเงื่อนไขโดยมิต้องแจ้งให้ทราบล่วงหน้า
        </span>
      </p>
      <div className="flex flex-row h-1 space-x-4 mb-9">
        <div className="bg-success rounded-full flex-1" />
        <div className="bg-success rounded-full flex-1" />
      </div>
      <div className="mb-10">
        <div className="font-bold text-lg mb-4">
          ต้องการใช้ซอฟต์แวร์ ใดบ้าง?<span className="text-danger">*</span>
        </div>
        <div className="space-y-4">
          <Controller
            control={control}
            name="useSoftware"
            render={({ value, onChange }) => (
              <CheckboxGroup
                colorScheme="green"
                value={value}
                onChange={(valueChange) => {
                  onChange(valueChange);
                }}
              >
                <Stack spacing="4">
                  <Checkbox value="1">On-demand service platform</Checkbox>
                  <Checkbox value="2">Transport management system</Checkbox>
                  <Checkbox value="3">Warehouse management system</Checkbox>
                  <Checkbox value="4">Finance system</Checkbox>
                  <Checkbox value="5">Overall Monitor</Checkbox>
                </Stack>
              </CheckboxGroup>
            )}
          />
        </div>
      </div>
      <div className="mb-10">
        <div className="mb-4">
          <span className="font-bold text-lg">
            สนใจเข้าร่วมเป็นผู้ทดสอบหรือไม่?<span className="text-danger">*</span>
          </span>
          <span className="block">(รับส่วนลด 50% เมื่อพัฒนาเสร็จสิ้น)</span>
        </div>
        <div className="space-y-4">
          <Controller
            control={control}
            name="testSoftware"
            render={({ value, onChange }) => (
              <RadioGroup
                colorScheme="green"
                value={value}
                onChange={(valueChange) => {
                  onChange(valueChange);
                }}
              >
                <Stack spacing="4">
                  <Radio value="1">สนใจ</Radio>
                  <Radio value="0">ไม่สนใจ</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </div>
      </div>
      <div className="mb-10">
        <div className="text-lg mb-4">
          <span className="font-bold">
            มีแผนจะใช้งานช่วงเวลาใด?<span className="text-danger">*</span>
          </span>
        </div>
        <div className="space-y-4">
          <Controller
            control={control}
            name="usePlan"
            render={({ value, onChange }) => (
              <RadioGroup
                colorScheme="green"
                value={value}
                onChange={(valueChange) => {
                  onChange(valueChange);
                }}
              >
                <Stack spacing="4">
                  <Radio value="1">เร็วที่สุด</Radio>
                  <Radio value="2">ภายใน 3 เดือน</Radio>
                  <Radio value="3">ภายในปีนี้ (2021)</Radio>
                  <Radio value="4">อีก 1 - 2 ปี</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </div>
      </div>
      <div className="mb-10">
        <div className="text-lg mb-4">
          <span className="font-bold">ปัญหาที่พบเจอในงานปัจจุบัน</span>
          <span className="font-bold block">อยากให้ GIZTIX ONE Solution แก้ไขปัญหาตรงจุดใด</span>
        </div>
        <div className="space-y-4">
          <Controller
            control={control}
            name="problemRemark"
            render={({ value, onChange }) => (
              <Textarea
                focusBorderColor="gray.300"
                placeholder="ระบุ (ถ้ามี)"
                value={value}
                onChange={(valueChange) => {
                  onChange(valueChange);
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="flex flex-row space-x-4">
        <button
          onClick={() => onBack()}
          className="w-full bg-transparent border-solid border border-gray-300 py-3 rounded-lg focus:outline-none"
        >
          กลับ
        </button>
        <button
          onClick={() => onConfirmSend()}
          className={`w-full bg-success py-3 rounded-lg text-white focus:outline-none ${
            loading ? 'opacity-60' : ''
          }`}
          disabled={loading}
        >
          ยืนยันข้อมูล
        </button>
      </div>
    </div>
  );
};

export default FormBusiness;
