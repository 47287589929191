import React from 'react';
import { ButtonSwitchLanguage } from 'components';
import Logo from 'assets/svg/logo-white.svg';

const Footer = () => {
  return (
    <div className="flex flex-row text-sm lg:text-base">
      <div className="w-full lg:w-2/5">
        <div className="text-2xl md:text-3xl font-bold flex flex-col mb-9">
          <div>มีข้อสงสัยใช่ไหม?</div>
          <div>เราพร้อมดูแลคุณเสมอ</div>
        </div>
        <div className="mb-6">สอบถามเพิ่มเติมได้ที่:</div>
        <div className="mb-6">
          <div className="font-bold">คุณโสฬส อดุลย์จิตต์ (บิน)</div>
          <div>ผู้จัดการฝ่ายขาย GIZTIX ONE Solution</div>
          <div>เบอร์โทรติดต่อ: (+66) 9-2246-3652</div>
          <div>อีเมล: soros@giztix.com</div>
        </div>
        <div className="border-solid border-gray-500 border-b my-6 md:my-9 w-full md:w-2/4" />
        <div>
          <div className="font-bold">บริษัท จิซทิกซ์ จำกัด</div>
          <div>เลขที่ 101 , ตึก ทรู ดิจิทัล พาร์ค ชั้น 15</div>
          <div>ห้องเลขที่ 1507-1509 ถนน สุขุมวิท</div>
          <div>แขวงบางจาก เขตพระโขนง กรุงเทพ 10260</div>
          <div>เบอร์โทรติดต่อ: 02-059-7149</div>
        </div>
        <div className="border-solid border-gray-500 border-b my-6 md:my-9 w-full md:w-3/4" />
        <div>
          <div className="font-bold">ผลิตภัณฑ์อื่นๆ</div>
          <div>
            <a
              rel="noreferrer"
              href="http://giztix.com"
              target="_blank"
              className="hover:underline"
            >
              giztix.com
            </a>
          </div>
          <div>
            <a
              rel="noreferrer"
              href="http://maninnovation.com"
              target="_blank"
              className="hover:underline"
            >
              maninnovation.com
            </a>
          </div>
          <div>
            <a
              rel="noreferrer"
              href="http://biztech.maninnovation.com"
              target="_blank"
              className="hover:underline"
            >
              biztech.maninnovation.com
            </a>
          </div>
          <div>
            <a
              rel="noreferrer"
              href="http://thumbweb.maninnovation.com"
              target="_blank"
              className="hover:underline"
            >
              thumbweb.maninnovation.com
            </a>
          </div>
        </div>
        <div className="border-solid border-gray-500 border-b my-6 md:my-9 w-full md:w-3/4" />
        <div className="mb-5">
          <ButtonSwitchLanguage />
        </div>
        <div>© 2021 GIZTIX Co., Ltd. สงวนสิทธิ์ทุกประการ</div>
      </div>
      <div
        className="hidden lg:flex lg:flex-1 lg:flex-row lg:justify-center lg:items-center"
        data-sal="fade-in"
        data-sal-duration="1000"
        data-sal-easing="ease"
      >
        <div className="h-auto w-2/4">
          <Logo />
        </div>
      </div>
    </div>
  );
};

export default Footer;
