import React, { useState, useEffect } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import validateSchema from './validateSchema';
import { FormValue } from './formValue.model';
import initialValue from './initialValue';
import FormInformation from './FormInformation';
import FormConfirmOTP from './FormConfirmOTP';
import FormBusiness from './FormBusiness';
import FormSuccess from './FormSuccess';

type ModalAboutProps = {
  visible: boolean;
  onCancel: () => void;
};

const ModalAbout: React.FC<ModalAboutProps> = (props) => {
  const { visible, onCancel } = props;
  const [step, setStep] = useState<'information' | 'otp' | 'business' | 'success'>('information');
  const methods = useForm<FormValue>({
    resolver: yupResolver(validateSchema),
    defaultValues: initialValue,
    shouldUnregister: false,
  });

  const { setValue, watch, reset } = methods;
  const watchIsVerifyOTP = watch('isVerifyOTP');

  useEffect(() => {
    if (visible) {
      setStep('information');
    }

    return () => {
      reset({
        ...initialValue,
      });
    };
  }, [visible]);

  return (
    <Drawer isOpen={visible} placement="right" onClose={() => onCancel()} size="lg">
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton className="text-black" />
          <FormProvider {...methods}>
            <DrawerBody className="text-black my-5">
              <div className="px-2 py-2 md:px-10 md:py-10">
                {step === 'information' && (
                  <FormInformation
                    onCancel={() => onCancel()}
                    onSubmit={() => {
                      if (!watchIsVerifyOTP) {
                        setStep('otp');
                      } else {
                        setStep('business');
                      }
                    }}
                  />
                )}

                {step === 'otp' && (
                  <FormConfirmOTP
                    onBack={() => setStep('information')}
                    onSubmit={() => {
                      setValue('isVerifyOTP', true);
                      setStep('business');
                    }}
                  />
                )}

                {step === 'business' && (
                  <FormBusiness
                    onBack={() => setStep('information')}
                    onSubmit={() => setStep('success')}
                  />
                )}

                {step === 'success' && <FormSuccess onClose={() => onCancel()} />}
              </div>
            </DrawerBody>
          </FormProvider>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default ModalAbout;
